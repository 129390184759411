define("ember-cp-validations/validators/inline", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   *  Accepts a custom `validate` function.
   *
   *  ## Examples
   *
   *  ```javascript
   *  validator('inline', {
   *    username: 'offirgolan',
   *    validate(value, options, model, attribute) {
   *      return value === options.username ?
   *             true :
   *             `Username must be ${options.username}`;
   *    }
   *  });
   *  ```
   *
   *  @class Inline
   *  @module Validators
   *  @extends Base
   */
  var _default = _base.default.extend({
    /**
     * Override the validator's `validate` method with the one that was
     * passed in via the options.
     *
     * @method buildOptions
     * @param  {Object}     options
     * @param  {Object}     defaultOptions
     * @param  {Object}     globalOptions
     * @return {Object}
     */
    buildOptions(options = {}, ...args) {
      (false && !(options && typeof options.validate === 'function') && Ember.assert("[validator:inline] You must pass in a validate function", options && typeof options.validate === 'function'));
      const opts = Ember.assign({}, options);
      this.validate = opts.validate;
      delete opts.validate;
      return this._super(opts, ...args);
    }

  });

  _exports.default = _default;
});